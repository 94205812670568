import { ButtonPrimary } from '@components/Button';
import { Grid } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';

interface SlideInterface {
  id?: string;
  image: string;
  icon: string;
  description: string;
  link: string;
  title: string;
  activeBanner: number;
}

const preloadImageDesktop =
  'https://res.cloudinary.com/dwwnfqlxc/image/upload/v1691403420/web3-acceleration-banner_d93vil.webp';
const preloadImageMobile =
  'https://res.cloudinary.com/dwwnfqlxc/image/upload/v1691402380/web3-banner-mobile_u7aiql.png';

const { useBreakpoint } = Grid;

const Slide = ({
  id,
  image,
  icon,
  description,
  link,
  title,
  activeBanner,
}: SlideInterface) => {
  const { t } = useTranslation();
  const screen = useBreakpoint();

  const [preloadImage, setPreloadImage] = useState('');

  useEffect(() => {
    setPreloadImage(screen.xs ? preloadImageMobile : preloadImageDesktop);
  }, [screen]);

  const imageUrl = activeBanner === 0 ? preloadImage : image;

  return (
    <>
      <div className="home-slide">
        <div className="home-slide__container">
          <div className="home-slide__image">
            {preloadImage && (
              <Image
                src={imageUrl}
                alt={id}
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL={imageUrl}
                priority
              />
            )}
          </div>
          <div className="home-slide__overlay" />

          {screen.md && (
            <div className="home-slide__icon">
              <Image
                src={icon}
                alt={id}
                layout="responsive"
                quality={100}
                width={478}
                height={478}
              />
            </div>
          )}

          <div className="home-slide__left">
            <h1
              className="home-slide__left--title"
              dangerouslySetInnerHTML={{ __html: t(title) }}
            />

            <div
              className="home-slide__left--desc"
              dangerouslySetInnerHTML={{ __html: t(description) }}
            />

            <div className="home-slide__left--group-btn">
              <Link href={link}>
                <a>
                  <ButtonPrimary isSquareBorder isRawColorBackground>
                    {t('home.txtLearnMore')}
                  </ButtonPrimary>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slide;
