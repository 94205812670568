import { useInterval } from '@common/hook';
import { Carousel, Grid } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import Slide from './slide';

import web3AccelerationIcon from 'public/images/web3-acceleration-banner-icon.webp';
import web3AccelerationMobile from 'public/images/web3-banner-mobile.webp';
import web3Acceleration from 'public/images/home/web3-acceleration-banner.webp';

import softwareDevelopmentIcon from 'public/images/software-development-banner-icon.webp';
import softwareDevelopmentMobile from 'public/images/software-development-banner-mobile.webp';
import softwareDevelopment from 'public/images/home/software-development-banner.webp';

import mobileApplicationIcon from 'public/images/mobile-application-banner-icon.webp';
import mobileApplicationMobile from 'public/images/mobile-application-banner-mobile.webp';
import mobileApplication from 'public/images/home/mobile-application-banner.webp';

import webAppIcon from 'public/images/website-webapp-banner-icon.webp';
import webAppMobile from 'public/images/website-webapp-banner-mobile.webp';
import webApp from 'public/images/home/website-webapp-banner.webp';

const DELAY_SECOND = 4000;

const slides = [
  {
    id: 'software-development',

    image: web3Acceleration,
    imageMobile: web3AccelerationMobile,
    icon: softwareDevelopmentIcon,
    name: 'home.txtCustomSoftwareDevelopment',
    title: 'home.txtCustomSoftwareDevelopmentTitle',
    titleMobile: 'home.txtCustomSoftwareDevelopmentTitleMobile',
    description: 'home.txtCustomSoftwareDevelopmentDescription',
    descriptionMobile: 'home.txtCustomSoftwareDevelopmentDescription',
    link: '/services/custom-software-development',
  },
  {
    id: 'mobile-application',
    image: softwareDevelopment,
    imageMobile: softwareDevelopmentMobile,
    icon: mobileApplicationIcon,
    name: 'home.txtMobileAppDevelopment',
    title: 'home.txtMobileAppDevelopmentTitle',
    titleMobile: 'home.txtMobileAppDevelopmentTitleMobile',
    description: 'home.txtMobileAppDevelopmentDescription',
    descriptionMobile: 'home.txtMobileAppDevelopmentDescription',
    link: '/services/mobile-app-development',
  },
  {
    id: 'website-webapp',
    image: mobileApplication,
    imageMobile: mobileApplicationMobile,
    icon: webAppIcon,
    name: 'home.txtWebAppDevelopment',
    title: 'home.txtWebAppDevelopmentTitle',
    titleMobile: 'home.txtWebAppDevelopmentTitleMobile',
    description: 'home.txtWebAppDevelopmentDescription',
    descriptionMobile: 'home.txtWebAppDevelopmentDescription',
    link: '/services/web-development',
  },
  {
    id: 'web3-acceleration',
    image: webApp,
    imageMobile: webAppMobile,
    icon: web3AccelerationIcon,
    name: 'home.txtBlockchainDevelopment',
    title: 'home.txtWeb3AccelerationTitle',
    titleMobile: 'home.txtWeb3AccelerationTitleMobile',
    description: 'home.txtWeb3AccelerationDescription',
    descriptionMobile: 'home.txtWeb3AccelerationDescriptionMobile',
    link: '/services/blockchain-development',
  },
];

const { useBreakpoint } = Grid;

const Banner = () => {
  const { t } = useTranslation();

  const { sm } = useBreakpoint();
  const carouselRef = useRef(null);
  const [activeBanner, setActiveBanner] = useState(0);
  const activeBannerInterval = useInterval(() => handleActiveBanner(), DELAY_SECOND);
  const isMobile = !sm;

  useEffect(() => {
    carouselRef.current?.goTo(activeBanner);
    activeBannerInterval.start();
    return () => {
      activeBannerInterval.stop();
    };
  }, [activeBanner]);

  const handleActiveBanner = () => {
    if (activeBanner < slides.length - 1) {
      setActiveBanner(activeBanner < slides.length - 1 ? (prev) => prev + 1 : 0);
    } else {
      setActiveBanner(0);
    }
  };

  const handleClickSlide = (slide: number) => () => {
    setActiveBanner(slide);
    carouselRef.current?.goTo(slide);
  };

  return (
    <div className="home-banner">
      <Carousel
        dots={isMobile}
        slidesToShow={1}
        autoplay={false}
        speed={1500}
        fade
        pauseOnHover={false}
        ref={carouselRef}
      >
        {slides.map(({ id, image, icon, imageMobile, description, link, title, titleMobile, descriptionMobile }) => (
          <Slide
            image={sm ? image : imageMobile}
            activeBanner={activeBanner}
            icon={icon}
            id={id}
            key={id}
            title={!isMobile ? title : titleMobile}
            description={!isMobile ? description : descriptionMobile}
            link={link}
          />
        ))}
      </Carousel>

      {!isMobile && (
        <div className="home-banner__navigation">
          <div className="navigation-flex">
            {slides.map(({ id, name }, index) => (
              <div
                className={classNames({
                  'home-banner__navigation--item': true,
                  'home-banner__navigation--item-active': index === activeBanner,
                })}
                key={id}
                onClick={handleClickSlide(index)}
              >
                {t(name)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Banner;
